export default class SurveyModalSection {
    firstForm = document.querySelector('div[data-popup-modal="survey-first"]');
    secondForm = document.querySelector('div[data-popup-modal="survey-second"]');
    thirdForm = document.querySelector('div[data-popup-modal="survey-third"]');
    rating = {
        comfort: 0,
        accessory: 0,
        cleaning: 0,
        location: 0,
        feature: 0,
        personnel: 0,
        booking: 0,
        register: 0,
        contact: 0,
    };
    surveyFirstModal = 'survey-first';
    surveySecondModal = 'survey-second';
    surveyThirdModal = 'survey-third';
    surveyThanksModal = 'survey-thanks';
    notification = null;
    settings = null;
    locale = null;

    constructor(notification, settings, locale) {
        this.notification = notification;
        this.settings = settings;
        this.locale = locale;

        // ! TODO: wszystko poniżej jest do kompletnego refaktoru, kod jest skandalicznie długi i bezsensowny
        this.bindModal();
    }

    bindModal() {
        const { rating, firstForm, secondForm, thirdForm } = this;
        let orderId = 0;
        if (this.settings("surveyData") !== '') {
            orderId = Number(this.settings("surveyData").split('_')[1]);
        }

        if (firstForm !== null || secondForm !== null || thirdForm !== null) {
            const firstNextButton = firstForm.querySelector('.btn-next');
            const secoundNextButton = secondForm.querySelector('.btn-next');
            const sendDataButton = thirdForm.querySelector('.btn-next');

            const buttonsFirstModal = firstForm.querySelectorAll('button');
            const buttonsSecondModal = secondForm.querySelectorAll('button');
            const buttonsThirdModal = thirdForm.querySelectorAll('button');

            const gradeComfort = firstForm.querySelector('.grade-comfort');
            const gradeAccessory = firstForm.querySelector('.grade-accessory');
            const gradeCleaning = firstForm.querySelector('.grade-cleaning');
            const gradeLocation = secondForm.querySelector('.grade-location');
            const gradeFeature = secondForm.querySelector('.grade-feature');
            const gradePersonnel = thirdForm.querySelector('.grade-personnel');
            const gradeBooking = thirdForm.querySelector('.grade-booking');
            const gradeRegister = thirdForm.querySelector('.grade-register');
            const gradeContact = thirdForm.querySelector('.grade-contact');

            const comfortComplainButtons = gradeComfort.querySelectorAll('button');
            const accessoryComplainButtons = gradeAccessory.querySelectorAll('button');
            const cleaningComplainButtons = gradeCleaning.querySelectorAll('button');
            const locationComplainButtons = gradeLocation.querySelectorAll('button');
            const featureComplainButtons = gradeFeature.querySelectorAll('button');
            const personnelComplainButtons = gradePersonnel.querySelectorAll('button');
            const bookingComplainButtons = gradeBooking.querySelectorAll('button');
            const registerComplainButtons = gradeRegister.querySelectorAll('button');

            const comfortRating = gradeComfort.querySelectorAll('.comfort');
            const accessoryRating = gradeAccessory.querySelectorAll('.accessory');
            const cleaningRating = gradeCleaning.querySelectorAll('.cleaning');
            const locationRating = gradeLocation.querySelectorAll('.location');
            const featureRating = gradeFeature.querySelectorAll('.feature');
            const personnelRating = gradePersonnel.querySelectorAll('.personnel');
            const bookingRating = gradeBooking.querySelectorAll('.booking');
            const registerRating = gradeRegister.querySelectorAll('.register');

            const otherReasonComfort = gradeComfort.querySelector('.other');
            const otherReasonAccessory = gradeAccessory.querySelector('.other');
            const otherReasonCleaning = gradeCleaning.querySelector('.other');
            const otherReasonLocation = gradeLocation.querySelector('.other');
            const otherReasonFeature = gradeFeature.querySelector('.other');
            const otherReasonPersonnel = gradePersonnel.querySelector('.other');
            const otherReasonBooking = gradeBooking.querySelector('.other');
            const otherReasonRegister = gradeRegister.querySelector('.other');

            const surveycontact = gradeContact.querySelector('#surveycontact');

            this.showSurveyReason();

            //rating
            //first page
            comfortRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradeComfort.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.comfort = currentRating;

                    if (rating.comfort < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            accessoryRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradeAccessory.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.accessory = currentRating;

                    if (rating.accessory < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            cleaningRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradeCleaning.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.cleaning = currentRating;

                    if (rating.cleaning < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            //second page
            locationRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradeLocation.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.location = currentRating;

                    if (rating.location < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            featureRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradeFeature.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.feature = currentRating;

                    if (rating.feature < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            //third page
            personnelRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradePersonnel.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.personnel = currentRating;

                    if (rating.personnel < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            bookingRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradeBooking.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.booking = currentRating;

                    if (rating.booking < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            registerRating.forEach((ratings, i) => {
                ratings.addEventListener('click', () => {
                    const ratingReason = gradeRegister.querySelector('.rating-reason');
                    let currentRating = this.surveyCorrectRating(i + 1);
                    rating.register = currentRating;

                    if (rating.register < 4) {
                        if (ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.remove('d-none');
                        }
                    } else {
                        if (!ratingReason.classList.contains('d-none')) {
                            ratingReason.classList.add('d-none');
                        }
                    }
                });
            });

            //other options
            otherReasonComfort.addEventListener('click', () => {
                const txtarea = gradeComfort.querySelector('textarea');

                if (otherReasonComfort.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            otherReasonAccessory.addEventListener('click', () => {
                const txtarea = gradeAccessory.querySelector('textarea');

                if (otherReasonAccessory.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            otherReasonCleaning.addEventListener('click', () => {
                const txtarea = gradeCleaning.querySelector('textarea');

                if (otherReasonCleaning.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            otherReasonLocation.addEventListener('click', () => {
                const txtarea = gradeLocation.querySelector('textarea');

                if (otherReasonLocation.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            otherReasonFeature.addEventListener('click', () => {
                const txtarea = gradeFeature.querySelector('textarea');

                if (otherReasonFeature.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            otherReasonPersonnel.addEventListener('click', () => {
                const txtarea = gradePersonnel.querySelector('textarea');

                if (otherReasonPersonnel.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            otherReasonBooking.addEventListener('click', () => {
                const txtarea = gradeBooking.querySelector('textarea');

                if (otherReasonBooking.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            otherReasonRegister.addEventListener('click', () => {
                const txtarea = gradeRegister.querySelector('textarea');

                if (otherReasonRegister.classList.contains('active')) {
                    txtarea.classList.add('d-none');
                } else {
                    txtarea.classList.remove('d-none');
                }
            });

            //button
            buttonsFirstModal.forEach(item => {
                item.addEventListener('click', () => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    } else {
                        item.classList.add('active');
                    }
                });
            });

            buttonsSecondModal.forEach(item => {
                item.addEventListener('click', () => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    } else {
                        item.classList.add('active');
                    }
                });
            });

            buttonsThirdModal.forEach(item => {
                item.addEventListener('click', () => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    } else {
                        item.classList.add('active');
                    }
                });
            });

            firstNextButton.addEventListener('click', () => {
                if (rating.comfort !== 0 && rating.accessory !== 0 && rating.cleaning !== 0) {
                    let isAllValid = true;

                    if (rating.comfort < 4) {
                        let comfortTxt = gradeComfort.querySelector('textarea');
                        let comfortTextarea = '';
                        if (otherReasonComfort.classList.contains('active')) {
                            comfortTextarea = gradeComfort.querySelector('textarea').value;
                        }
                        if (comfortTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));
                            if (!comfortTxt.classList.contains('textarea-error')) {
                                comfortTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (comfortTxt.classList.contains('textarea-error')) {
                                comfortTxt.classList.remove('textarea-error');
                            }
                        }
                    }

                    if (rating.accessory < 4) {
                        let accessoryTxt = gradeAccessory.querySelector('textarea');
                        let accessoryTextarea = '';
                        if (otherReasonAccessory.classList.contains('active')) {
                            accessoryTextarea = gradeAccessory.querySelector('textarea').value;
                        }

                        if (accessoryTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));
                            if (!accessoryTxt.classList.contains('textarea-error')) {
                                accessoryTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (accessoryTxt.classList.contains('textarea-error')) {
                                accessoryTxt.classList.remove('textarea-error');
                            }
                        }
                    }

                    if (rating.cleaning < 4) {
                        let cleaningTxt = gradeCleaning.querySelector('textarea');
                        let cleaningTextarea = '';
                        if (otherReasonCleaning.classList.contains('active')) {
                            cleaningTextarea = gradeCleaning.querySelector('textarea').value;
                        }

                        if (cleaningTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));
                            if (!cleaningTxt.classList.contains('textarea-error')) {
                                cleaningTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (cleaningTxt.classList.contains('textarea-error')) {
                                cleaningTxt.classList.remove('textarea-error');
                            }
                        }
                    }

                    if (isAllValid) {
                        modal.close(this.surveyFirstModal);
                        modal.open(this.surveySecondModal);
                    }
                } else {
                    this.notification.show(this.notification.types.error, this.locale("rateYourStay"));
                }
            });

            secoundNextButton.addEventListener('click', () => {
                if (rating.location !== 0 && rating.feature !== 0) {
                    let isAllValid = true;

                    if (rating.location < 4) {
                        let locationTxt = gradeLocation.querySelector('textarea');
                        let locationTextarea = '';
                        if (otherReasonLocation.classList.contains('active')) {
                            locationTextarea = gradeLocation.querySelector('textarea').value;
                        }

                        if (locationTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));

                            if (!locationTxt.classList.contains('textarea-error')) {
                                locationTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (locationTxt.classList.contains('textarea-error')) {
                                locationTxt.classList.remove('textarea-error');
                            }
                        }
                    }

                    if (rating.feature < 4) {
                        let featureTxt = gradeFeature.querySelector('textarea');
                        let featureTextarea = '';
                        if (otherReasonFeature.classList.contains('active')) {
                            featureTextarea = gradeFeature.querySelector('textarea').value;
                        }

                        if (featureTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));
                            if (!featureTxt.classList.contains('textarea-error')) {
                                featureTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (featureTxt.classList.contains('textarea-error')) {
                                featureTxt.classList.remove('textarea-error');
                            }
                        }
                    }

                    if (isAllValid) {
                        modal.close(this.surveySecondModal);
                        modal.open(this.surveyThirdModal);
                    }
                } else {
                    this.notification.show(this.notification.types.error, this.locale("rateYourStay"));
                }
            });

            sendDataButton.addEventListener('click', async () => {
                if (rating.personnel !== 0 && rating.booking !== 0 && rating.register !== 0) {
                    let isAllValid = true;

                    let comfortComplain = '';
                    let accessoryComplain = '';
                    let cleaningComplain = '';
                    let locationComplain = '';
                    let featureComplain = '';
                    let personnelComplain = '';
                    let bookingComplain = '';
                    let registerComplain = '';

                    let comfortTextarea = '';
                    let accessoryTextarea = '';
                    let cleaningTextarea = '';
                    let locationTextarea = '';
                    let featureTextarea = '';
                    let personnelTextarea = '';
                    let bookingTextarea = '';
                    let registerTextarea = '';

                    if (rating.comfort < 4 && rating.comfort > 0) {
                        if (otherReasonComfort.classList.contains('active')) {
                            comfortTextarea = gradeComfort.querySelector('textarea').value;
                        }

                        comfortComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                comfortComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }

                    if (rating.accessory < 4 && rating.accessory > 0) {
                        if (otherReasonAccessory.classList.contains('active')) {
                            accessoryTextarea = gradeAccessory.querySelector('textarea').value;
                        }

                        accessoryComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                accessoryComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }

                    if (rating.cleaning < 4 && rating.cleaning > 0) {
                        if (otherReasonCleaning.classList.contains('active')) {
                            cleaningTextarea = gradeCleaning.querySelector('textarea').value;
                        }

                        cleaningComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                cleaningComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }

                    if (rating.location < 4 && rating.location > 0) {
                        if (otherReasonLocation.classList.contains('active')) {
                            locationTextarea = gradeLocation.querySelector('textarea').value;
                        }

                        locationComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                locationComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }

                    if (rating.feature < 4 && rating.feature > 0) {
                        if (otherReasonFeature.classList.contains('active')) {
                            featureTextarea = gradeFeature.querySelector('textarea').value
                        }

                        featureComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                featureComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }

                    if (rating.personnel < 4 && rating.personnel > 0) {
                        let personnelTxt = gradePersonnel.querySelector('textarea');
                        if (otherReasonPersonnel.classList.contains('active')) {
                            personnelTextarea = gradePersonnel.querySelector('textarea').value
                        }

                        if (personnelTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));
                            if (!personnelTxt.classList.contains('textarea-error')) {
                                personnelTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (personnelTxt.classList.contains('textarea-error')) {
                                personnelTxt.classList.remove('textarea-error');
                            }
                        }

                        personnelComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                personnelComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }

                    if (rating.booking < 4 && rating.booking > 0) {
                        let bookingTxt = gradeBooking.querySelector('textarea');
                        if (otherReasonBooking.classList.contains('active')) {
                            bookingTextarea = gradeBooking.querySelector('textarea').value
                        }

                        if (bookingTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));
                            if (!bookingTxt.classList.contains('textarea-error')) {
                                bookingTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (bookingTxt.classList.contains('textarea-error')) {
                                bookingTxt.classList.remove('textarea-error');
                            }
                        }

                        bookingComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                bookingComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }

                    if (rating.register < 4 && rating.register > 0) {
                        let registerTxt = gradeRegister.querySelector('textarea');
                        if (otherReasonRegister.classList.contains('active')) {
                            registerTextarea = gradeRegister.querySelector('textarea').value
                        }

                        if (registerTextarea.length > 400) {
                            isAllValid = false;

                            this.notification.show(this.notification.types.error, this.locale("exceededMaxCharacters"));
                            if (!registerTxt.classList.contains('textarea-error')) {
                                registerTxt.classList.add('textarea-error');
                            }
                        } else {
                            if (registerTxt.classList.contains('textarea-error')) {
                                registerTxt.classList.remove('textarea-error');
                            }
                        }

                        registerComplainButtons.forEach(item => {
                            if (item.classList.contains('active')) {
                                registerComplain += item.getAttribute('data-value') + ',';
                            }
                        });
                    }
                    if (isAllValid) {
                        let contactWish = surveycontact.checked ? 1 : 0;
                        let otherComplainIds = ['', comfortTextarea, accessoryTextarea, cleaningTextarea, locationTextarea, featureTextarea, personnelTextarea, bookingTextarea, registerTextarea];
                        let complainIds = ['', comfortComplain, accessoryComplain, cleaningComplain, locationComplain, featureComplain, personnelComplain, bookingComplain, registerComplain];
                        let ratingPkt = [0, rating.comfort, rating.accessory, rating.cleaning, rating.location, rating.feature, rating.personnel, rating.booking, rating.register];
                        let ratingPktSum = 0;
                        let ratingPktAverage = 0;
                        /*                        let surveyResult = {
                                                    "order_id": orderId,
                                                    "contact": contactWish,
                                                    "complain_ids": complainIds,
                                                    "rating_pkt": ratingPkt,
                                                    "other_complain_ids": otherComplainIds,
                                                };
                                                let postData = `OrderId=${orderId}&ComplainIds=${complainIds[type]}&Type=${type}&Rating=${ratingPkt[type]}&OtherComplains=${otherComplainIds[type]}`;
                        
                                                const response = await fetch(this.settings.url.surveySendData, {
                                                    method: 'POST',
                                                    body: postData,
                                                    headers: {
                                                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                                                        'X-Requested': 'With: XMLHttpRequest'
                                                    }
                                                });
                                                const res = await response.text();*/
                        for (let type = 1; type < 9; type++) {
                            let postData = `OrderId=${orderId}&ContactWish=${contactWish}&ComplainIds=${complainIds[type]}&Type=${type}&Rating=${ratingPkt[type]}&OtherComplains=${otherComplainIds[type]}`;

                            ratingPktSum += ratingPkt[type];
                            const response = await fetch(this.settings("url").surveySendData, {
                                method: 'POST',
                                body: postData,
                                headers: {
                                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                                    'X-Requested': 'With: XMLHttpRequest'
                                }
                            });
                            const res = await response.text();
                        }

                        ratingPktAverage = ratingPktSum / 8;
                        if (ratingPktAverage >= 4.5) {
                            const googleTravel = document.getElementById('googleTravel');
                            googleTravel.style.display = 'block';
                        }

                        modal.close(this.surveyThirdModal);
                        modal.open(this.surveyThanksModal);
                    }

                } else {
                    this.notification.show(this.notification.types.error, this.locale("rateYourStay"));
                }
            });
        }
    }

    surveyCorrectRating = (rating) => {
        if (rating === 5) {
            return 1;
        } else if (rating === 4) {
            return 2;
        } else if (rating === 3) {
            return 3;
        } else if (rating === 2) {
            return 4;
        } else if (rating === 1) {
            return 5;
        }
    }

    showSurveyReason = () => {
        const { rating, firstForm, secondForm, thirdForm } = this;

        if (firstForm !== null || secondForm !== null || thirdForm !== null) {
            const gradeComfort = firstForm.querySelector('.grade-comfort');
            const gradeAccessory = firstForm.querySelector('.grade-accessory');
            const gradeCleaning = firstForm.querySelector('.grade-cleaning');
            const gradeLocation = secondForm.querySelector('.grade-location');
            const gradeFeature = secondForm.querySelector('.grade-feature');
            const gradePersonnel = thirdForm.querySelector('.grade-personnel');
            const gradeBooking = thirdForm.querySelector('.grade-booking');
            const gradeRegister = thirdForm.querySelector('.grade-register');

            const comfort = gradeComfort.querySelector('.rating-reason');
            if (rating.comfort < 4 && rating.comfort !== 0) {
                if (comfort.classList.contains('d-none')) {
                    comfort.classList.remove('d-none');
                }
            } else {
                if (!comfort.classList.contains('d-none')) {
                    comfort.classList.add('d-none');
                }
            }

            const accessory = gradeAccessory.querySelector('.rating-reason');
            if (rating.accessory < 4 && rating.accessory !== 0) {
                if (accessory.classList.contains('d-none')) {
                    accessory.classList.remove('d-none');
                }
            } else {
                if (!accessory.classList.contains('d-none')) {
                    accessory.classList.add('d-none');
                }
            }

            const cleaning = gradeCleaning.querySelector('.rating-reason');
            if (rating.cleaning < 4 && rating.cleaning !== 0) {
                if (cleaning.classList.contains('d-none')) {
                    cleaning.classList.remove('d-none');
                }
            } else {
                if (!cleaning.classList.contains('d-none')) {
                    cleaning.classList.add('d-none');
                }
            }

            const location = gradeLocation.querySelector('.rating-reason');
            if (rating.location < 4 && rating.location !== 0) {
                if (location.classList.contains('d-none')) {
                    location.classList.remove('d-none');
                }
            } else {
                if (!location.classList.contains('d-none')) {
                    location.classList.add('d-none');
                }
            }

            const feature = gradeFeature.querySelector('.rating-reason');
            if (rating.feature < 4 && rating.feature !== 0) {
                if (feature.classList.contains('d-none')) {
                    feature.classList.remove('d-none');
                }
            } else {
                if (!feature.classList.contains('d-none')) {
                    feature.classList.add('d-none');
                }
            }

            const personnel = gradePersonnel.querySelector('.rating-reason');
            if (rating.personnel < 4 && rating.personnel !== 0) {
                if (personnel.classList.contains('d-none')) {
                    personnel.classList.remove('d-none');
                }
            } else {
                if (!personnel.classList.contains('d-none')) {
                    personnel.classList.add('d-none');
                }
            }

            const booking = gradeBooking.querySelector('.rating-reason');
            if (rating.booking < 4 && rating.booking !== 0) {
                if (booking.classList.contains('d-none')) {
                    booking.classList.remove('d-none');
                }
            } else {
                if (!booking.classList.contains('d-none')) {
                    booking.classList.add('d-none');
                }
            }

            const register = gradeRegister.querySelector('.rating-reason');
            if (rating.register < 4 && rating.register !== 0) {
                if (register.classList.contains('d-none')) {
                    register.classList.remove('d-none');
                }
            } else {
                if (!register.classList.contains('d-none')) {
                    register.classList.add('d-none');
                }
            }
        }
    }
}