export default class Modal {
    triggers = document.querySelectorAll('.modal-trigger');
    navContainer = document.querySelector('.nav__container');
    modalsWithWhiteBackground = [
        'login',
        'register1'
    ];
    modalsCantClose = [
        'newsletter',
        'cookies',
        'survey-first',
        'survey-second',
        'survey-third',
    ];

    constructor() {
        this.bindTriggers();
    }

    bindTriggers = () => {
        this.triggers.forEach(trigger => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                const { modalTrigger } = trigger.dataset;
                this.open(modalTrigger);
            })
        });
    }

    open = (modalTrigger, timeout = null, onTimeout = null) => {
        const popupModal = document.querySelector(`[data-popup-modal="${modalTrigger}"]`);
        const closeButtonTop = popupModal.querySelector('.popup-modal__close');
        const closeButtonBottom = popupModal.querySelector('.popup-modal__buttons button.modal-close');

        if (
            this.modalsWithWhiteBackground.includes(modalTrigger)
            && document.body.clientWidth < 901
        ) {
            popupModal.classList.add('popup-modal--white');
        }
        popupModal.classList.add('popup-modal--visible');
        document.body.classList.add('no-overflow');
        document.documentElement.classList.add('no-overflow');

        [closeButtonTop, closeButtonBottom].forEach(button => {
            if (!this.modalsCantClose.includes(modalTrigger) && button !== null) {
                button.addEventListener('click', () => {
                    this.close(modalTrigger);
                });
            }
        });

        popupModal.addEventListener('click', (e) => {
            if (!this.modalsCantClose.includes(modalTrigger) && e.target === popupModal) {
                this.close(modalTrigger);
            }
        });
        if ((timeout != null) && (onTimeout != null)) {
            setTimeout(() => {
                onTimeout();
            }, timeout);
        }

        if (this.modalsCantClose.includes(modalTrigger)) {
            closeButtonTop.classList.add('popup-modal--not_visible')
        }
    }

    close = (modalTrigger) => {
        const popupModal = document.querySelector(`[data-popup-modal="${modalTrigger}"]`);
        const closeRedirectUrl = popupModal.dataset.closeRedirectUrl;
        popupModal.classList.remove('popup-modal--visible');
        const otherModals = document.querySelectorAll('.popup-modal--visible');

        if (
            otherModals.length === 0
            && (!this.navContainer?.classList.contains('nav__container--open')
            || document.body.clientWidth > 900)
        ) {
            document.body.classList.remove('no-overflow');
            document.documentElement.classList.remove('no-overflow');
        }

        if (closeRedirectUrl !== undefined) {
            window.location.href = window.location.origin + closeRedirectUrl;
        }
    }
}