export default class SearchModuleBase {

    searchBar = document.querySelector('.search-header');
    searchBarContainer = document.querySelector('.search-header__container');
    notification = null;
    settings = {
        citiesComponentDisabled: false,
        datesComponentDisabled: false,
        isApartmentsFromFooterView: false,
        startDate: null,
        endDate: null,
        minDate: null,
        maxDate: null,
        minSpan: null,
        maxSpan: null,
        minAdultsValue: 1,
        maxAdultsValue: 50,
        minKidsValue: 0,
        maxKidsValue: 10,
        kidsAgeValues: null,
        defaultKidsAgeValue: 11,
        kidsAgeForCheckbox: 3,
        peopleNumber: 0,
        peopleData: null,
        isMainPage: false
    };
    locale = {
        dateValidation: "The departure date must be different from the arrival date.",
        sameDateSelected: "Data wyjazdu musi być inna niż data przyjazdu",
        cityValidation: "You must select the city or region you want to search for.",
        numberOfPeople: 'Number of people:',
        kidsChecboxLabel: 'The child need an extra bed',
        selectCity: 'Select city',
        emailexist: 'This address email already exist',
        manyCities: 'Many cities',
        selectedMaxCitiesInSearch: 'You can choose 3 locations at a time',
        daysOfWeek: [],
        monthNames: [],
        cancelLabel: null,
        applyLabel: null,
        daysLabelSingularNominative: null,
        daysLabelPluralNominative: null,
        daysLabelPluralGenitive: null,
        arrival: null,
        departure: null,
        from: null,
        to: null,
        childrenAges: null,
        showMore: 'Show more',
        showLess: 'Show less',
        inputCannotBeEdited: 'This option cannot be edited!',
    };
    disabled = false;

    constructor(config, notification, disabledComponentKey = null) {
        this.notification = notification;
        this.prepareConfig(config);
        this.setStatus(disabledComponentKey);
    }

    setStatus = (disabledComponentKey) => {
        this.disabled = this.settings[disabledComponentKey] ?? false;
    }

    prepareConfig(config) {
        if (typeof config.locale === 'object') {
            for (const [key, value] of Object.entries(this.locale)) {
                if (typeof config.locale[key] === 'string') {
                    this.locale[key] = config.locale[key];
                }
                if (typeof config.locale[key] === 'object') {
                    this.locale[key] = config.locale[key];
                }
            }
        }

        if (typeof config.settings === 'object') {
            for (const [key, value] of Object.entries(this.settings)) {
                if (
                    typeof config.settings[key] === 'object' 
                    || typeof config.settings[key] === 'string' 
                    || typeof config.settings[key] === 'boolean'
                    || typeof config.settings[key] === 'number'
                ) {
                    this.settings[key] = config.settings[key];
                }
            }
        }

        const searchBox = document.querySelector('.search-box');
        if (searchBox === null) {
            this.settings.isMainPage = true;
        }
    }
}