require('../jquery/bootstrap-notify');

export default class Notification {

    types = {
        info: 'info',
        error: 'error',
        warning: 'warning',
        success: 'success',
    }

    constructor(locale) {
        this.locale = locale;

        this.setDefaults();
    }

    setDefaults = () => {
        $.notifyDefaults({
            z_index: 1600,
            allow_dismiss: true,
        });
    }

    show = (type, title, message = null) => {
        const notifyObject = {
            title: title,
            message: message,
        };

        $.notify(notifyObject, {
            type: type
        });
    }

    showDefaultError = (errorMessage = null) => {
        if (errorMessage !== null && process.env.ENVIRONMENT === "development") {
            console.error(errorMessage);
        }

        this.show(this.types.error, this.locale.errorApplicationMessage);
    }

    createHtml = (messageText, type = this.types.info) => {
        const container = document.createElement('div');
        container.classList.add('alert', this.getAlertTypeClass(type));

        const iconContainer = document.createElement('span');
        iconContainer.innerHTML = `<svg viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path></svg>`;
        container.appendChild(iconContainer);

        const messageContainer = document.createElement('div');
        messageContainer.classList.add('alert__container');

        const message = document.createElement('h5');
        message.innerText = messageText;
        messageContainer.appendChild(message);
        container.appendChild(messageContainer);

        return container;
    }

    getAlertTypeClass = (type) => {
        switch (type) {
            case this.types.success:
                return 'alert--success';

            case this.types.error:
                return 'alert--error';

            case this.types.warning:
                return 'alert--warning';

            case this.types.info:
            default:
                return 'alert--info';
        }
    }
}