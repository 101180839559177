export default class Navigation {
    nav = null;
    navContainer = null;
    subnavButtons = null;
    menuMobileButton = null;

    constructor() {
        this.nav = document.querySelector('.nav');
        this.navContainer = this.nav.querySelector('.nav__data');
        this.subnavButtons = this.nav.querySelectorAll('.nav-list__subnav');
        this.menuMobileButton = document.getElementById('mobileMenuButton');
        this.menuMobileButtonClose = document.getElementById('mobileMenuButtonClose');
        this.navMobileContainer = this.nav.querySelector('.mobile-data');
        this.mobileLanguageButtonClose = document.getElementById('mobileLanguageButtonClose');

        this.bindOnResizeEvent();
        this.bindSubnavButtons();
        this.bindMenuMobileButton();
        this.bindMobileLanguageButton();
        this.bindMobileLanguageCloseButton();
    }

    bindOnResizeEvent = () => {
        window.addEventListener('resize', () => {
            if (document.body.clientWidth > 900) {
                document.body.classList.remove('no-overflow');
                this.menuMobileButton.classList.remove('open');
                this.navMobileContainer.classList.remove('open');
            }
        });
    }

    bindSubnavButtons = () => {
        this.subnavButtons.forEach(button => {
            if (document.body.clientWidth > 900) {
                button.addEventListener('mouseover', () => {
                    button.classList.add('active');
                });
    
                button.addEventListener('mouseout', () => {
                    button.classList.remove('active');
                });
            }

            button.addEventListener('click', (e) => {
                if (e.target.parentElement === button) {
                    e.preventDefault();
                }
                if (document.body.clientWidth <= 900) {
                    button.classList.toggle('active');
                }
            });
        });
    }

    bindMenuMobileButton = () => {
        this.menuMobileButton.addEventListener('click', () => {
            this.navMobileContainer.classList.add('open');
            this.menuMobileButton.classList.add('open');
            document.body.classList.add('no-overflow');
        });

        this.menuMobileButtonClose.addEventListener('click', () => {
            this.navMobileContainer.classList.remove('open');
            this.menuMobileButton.classList.remove('open');
            document.body.classList.remove('no-overflow');
        });
    }

    bindMobileLanguageButton = () => {
        const button = document.getElementById('languageSelect');

        if (button) {
            button.addEventListener('click', () => {
                this.navMobileContainer.classList.add('language-container');
            });
        }
    }

    bindMobileLanguageCloseButton = () => {
        if (this.mobileLanguageButtonClose) {
            this.mobileLanguageButtonClose.addEventListener('click', () => {
                this.navMobileContainer.classList.remove('language-container');
            });
        }
    }
}