export default class Logout {

    buttons = document.querySelectorAll('.logout-button');

    constructor(config) {
        this.prepareConfig(config);
        this.bindButtons();
    }

    prepareConfig = (config) => {
        this.settings = config.settings;
    }

    bindButtons = () => {
        this.buttons.forEach(button => {
            button.addEventListener('click', async () => {
                let formData = '';
                formData += `ReturnUrl=&`;
                formData += `Lang=`;
    
                const response = await fetch(button.dataset.action, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-Requested': 'With: XMLHttpRequest'
                    }
                });
                const res = await response.text();
    
                // ! TODO: sprawdzić response
                if (res === '0') {
                    location.href = this.settings.mainPagePath;
                }
            });
        });
    }
}