export default class SliderCounter {
    displayDelay = 3;
    cssClass = {
        container: 'slider-counter',
        hidden: 'hidden'
    };

    add(data) {
        const { cssClass, displayDelay } = this;
        const index = data.displayIndex;
        const count = data.slideCount;
        const item = data.slideItems[data.index];
        const img = item.querySelector('img');
        let rightMargin = 0;

        if (img !== null) {
            const widthDiff = item.offsetWidth - img.offsetWidth;
            rightMargin = widthDiff / 2;
        }

        const container = document.createElement('div');
        container.classList.add(cssClass.container);
        container.style.right = `calc(1rem + ${rightMargin}px)`;

        const span = document.createElement('span');
        span.innerText = `${index} / ${count}`;

        this.removeCounters();

        container.appendChild(span);
        item.appendChild(container);

        setTimeout(() => {
            container.classList.add(cssClass.hidden);
        }, displayDelay * 1000);
    }

    removeCounters() {
        const { cssClass } = this;
        const items = document.querySelectorAll(`.${cssClass.container}`);

        items.forEach(item => {
            item.remove();
        });
    }
}