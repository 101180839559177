import Selectpicker from '../../../utilities/selectpicker.js';
import SearchModuleBase from './base.js';

export default class SearchPeopleModule extends SearchModuleBase {
    select = document.getElementById('peopleSelect');
    container = document.getElementById('peopleContainer');
    mobileDisplay = document.getElementById('searchGuests');
    mainInput = null;
    peopleNumberToDisplay = null;
    selectButton = null;
    clearFilterButton = null;
    closeButton = null;
    adultsNumberInput = null;
    kidsNumberInput = null;

    constructor(config, notification) {
        super(config, notification);
        this.selectpicker = new Selectpicker();
        this.selectButton = this.container.querySelector('.button--primary');
        this.clearFilterButton = this.container.querySelector('.button--tetriary');
        this.closeButton = this.container.querySelector('.close');
        this.mainInput = this.select.querySelector('input[type="text"]');
        this.adultsNumberInput = this.container.querySelector('input[name="adultsNumber"]');
        this.kidsNumberInput = this.container.querySelector('input[name="kidsNumber"]');
        this.peopleNumberToDisplay = parseInt(this.settings.peopleNumber);
        this.bindOpenPeopleSelectModal();
        this.bindClosePeopleSelectModal();
        this.bindAdultsSection();
        this.bindKidsSection();
        this.bindClearFilterButton();
        if (this.settings.peopleData !== null) {
            this.setPeopleData();
        }
    }

    bindOpenPeopleSelectModal = () => {
        const { select } = this;

        select.addEventListener('click', (e) => {
            if (
                e.target == select.querySelector('svg')
                || e.target == select.querySelector('input[type="text"]')
            ) {
                this.openModalHandler();
            }
        });
    }

    bindClosePeopleSelectModal = () => {
        const { container, selectButton, closeButton } = this;

        [container, closeButton].forEach(element => {
            element.addEventListener('click', (e) => {
                if (e.target == container || e.target == selectButton || e.target.parentElement == closeButton) {
                    this.closeModalHandler();
                }
            });
        });
    }

    openModalHandler = () => {
        if (document.body.clientWidth < 901) {
            document.body.classList.add('no-overflow');
            document.documentElement.classList.add('no-overflow');
        }
        this.container.classList.remove('search-container--close');
        this.container.classList.add('search-container--open');
    }

    closeModalHandler = () => {
        document.body.classList.remove('no-overflow');
        document.documentElement.classList.remove('no-overflow');
        this.container.classList.remove('search-container--open');
        this.container.classList.add('search-container--close');
    }

    bindAdultsSection = () => {
        const { container, adultsNumberInput } = this;
        const { minAdultsValue, maxAdultsValue } = this.settings;
        const peopleModule = container.querySelector('.people-module');
        const adultsSelect = peopleModule.querySelector('.people-select:first-child');
        const plusButton = adultsSelect.querySelector('button:last-child');
        const minusButton = adultsSelect.querySelector('button:first-child');

        plusButton.addEventListener('click', () => {
            const adultsValue = parseInt(adultsNumberInput.value);

            if (adultsValue >= minAdultsValue && adultsValue < maxAdultsValue) {
                adultsNumberInput.value = adultsValue + 1;
                this.peopleNumberToDisplay += 1;
                this.updateDisplayData();
            }
        });

        minusButton.addEventListener('click', () => {
            const adultsValue = parseInt(adultsNumberInput.value);

            if (adultsValue > minAdultsValue && adultsValue <= maxAdultsValue) {
                adultsNumberInput.value = adultsValue - 1;
                this.peopleNumberToDisplay -= 1;
                this.updateDisplayData();
            }
        });
    }

    bindKidsSection = () => {
        const { container, kidsNumberInput } = this;
        const { minKidsValue, maxKidsValue } = this.settings;
        const peopleModule = container.querySelector('.people-module');
        const kidsSelect = peopleModule.querySelector('.people-select:nth-child(2)');
        const kidsOptions = peopleModule.querySelector('.kids-options');
        const plusButton = kidsSelect.querySelector('button:last-child');
        const minusButton = kidsSelect.querySelector('button:first-child');

        plusButton.addEventListener('click', () => {
            const kidsValue = parseInt(kidsNumberInput.value);

            if (kidsValue >= minKidsValue && kidsValue < maxKidsValue) {
                kidsNumberInput.value = kidsValue + 1;
                this.peopleNumberToDisplay += 1;
                this.updateDisplayData();
                this.addKidsOptionsItem(kidsOptions);
            }
        });

        minusButton.addEventListener('click', () => {
            const kidsValue = parseInt(kidsNumberInput.value);

            if (kidsValue > minKidsValue && kidsValue <= maxKidsValue) {
                kidsNumberInput.value = kidsValue - 1;
                this.peopleNumberToDisplay -= 1;
                this.updateDisplayData();
                this.removeKidsOptionsItem(kidsOptions);
            }
        });
    }

    addKidsOptionsItem = (kidsOptionsContainer, childAge = null, needBed = null) => {
        const { kidsAgeValues, defaultKidsAgeValue, kidsAgeForCheckbox } = this.settings;
        const { locale } = this;
        const kidsOptionsItem = document.createElement('div');
        kidsOptionsItem.classList.add('kids-options__item');

        const label = document.createElement('label');
        label.classList.add("select", "select--small");
        const select = document.createElement('select');
        select.classList.add('selectpicker');
        for (let value in kidsAgeValues) {
            const string = kidsAgeValues[value];
            const option = document.createElement('option');
            option.value = value;
            select.dataset.prevValue = childAge ?? defaultKidsAgeValue;
            option.innerHTML = string;
            select.value = childAge ?? defaultKidsAgeValue;
            select.appendChild(option);
        }

        select.addEventListener('change', (e) => {
            const checkbox = select.parentElement.parentElement.parentElement.querySelector('.checkbox');

            if (parseInt(select.value) <= kidsAgeForCheckbox) {
                this.addKidsOptionsCheckbox(select);
                if (select.dataset.prevValue > kidsAgeForCheckbox && (checkbox === null || checkbox.checked)) {
                    this.peopleNumberToDisplay -= 1;
                }
            } else {
                this.removeKidsOptionsCheckbox(select);
                if (select.dataset.prevValue <= kidsAgeForCheckbox && (checkbox === null || !checkbox.checked)) {
                    this.peopleNumberToDisplay += 1;
                }
            }
            select.dataset.prevValue = select.value;
            this.updateDisplayData();
        });

        label.appendChild(select);
        kidsOptionsItem.appendChild(label);
        let itemsContainer = kidsOptionsContainer.querySelector('.kids-list-container');
        if (itemsContainer === null || itemsContainer.children.length === 0) {
            const heading = document.createElement('h3');
            heading.innerText = locale.childrenAges;
            kidsOptionsContainer.appendChild(heading);

            if (itemsContainer === null) {
                itemsContainer = document.createElement('div');
                itemsContainer.classList.add('kids-list-container');
                kidsOptionsContainer.appendChild(itemsContainer);
            }
        }
        itemsContainer.appendChild(kidsOptionsItem);
        $(select).selectpicker('refresh');

        label.addEventListener('click', () => {
            $(select).selectpicker('toggle');
        });

        if (childAge !== null && childAge <= kidsAgeForCheckbox) {
            this.addKidsOptionsCheckbox(select, needBed);
        }
    }

    removeKidsOptionsItem = (kidsOptionsContainer) => {
        const kidsListContainer = kidsOptionsContainer.querySelector('.kids-list-container');
        const kidsOptionsItem = kidsOptionsContainer.querySelector('.kids-options__item:last-child');
        kidsOptionsItem.remove();

        if (kidsListContainer.children.length === 0) {
            kidsOptionsContainer.querySelector('h3').remove();
            kidsListContainer.remove();
        }
    }

    addKidsOptionsCheckbox = (select, needBed = false) => {
        const { mainInput } = this;
        const { locale } = this;

        if (select.parentElement.parentElement.parentElement.getElementsByClassName('kids-options__checkbox-container').length == 0) {
            const container = document.createElement('div');
            container.classList.add('kids-options__checkbox-container');

            const label = document.createElement('label');
            label.innerHTML = this.locale.kidsChecboxLabel;
            container.appendChild(label);

            const input = document.createElement('input');
            input.type = 'checkbox';
            input.checked = needBed;
            input.classList.add('checkbox', 'checkbox--small');
            input.addEventListener('click', (e) => {
                if (e.target.checked) {
                    this.peopleNumberToDisplay += 1;
                } else {
                    this.peopleNumberToDisplay -= 1;
                }
                mainInput.value = `${locale.numberOfPeople} ${this.peopleNumberToDisplay}`;
            });

            label.appendChild(input);

            select.parentElement.parentElement.parentElement.appendChild(container);
        }
    }

    removeKidsOptionsCheckbox = (select) => {
        const container = select.parentElement.parentElement.parentElement.querySelector('.kids-options__checkbox-container');
        if (container !== null) {
            container.remove();
        }
    }

    bindClearFilterButton = () => {
        const { clearFilterButton, adultsNumberInput, kidsNumberInput, settings } = this;

        clearFilterButton.addEventListener('click', () => {
            const peopleModule = this.container.querySelector('.people-module');
            const kidsOptions = peopleModule.querySelector('.kids-options');
            while (kidsOptions.firstChild) {
                kidsOptions.removeChild(kidsOptions.firstChild);
            }
            this.peopleNumberToDisplay = settings.minAdultsValue + settings.minKidsValue;
            adultsNumberInput.value = settings.minAdultsValue;
            kidsNumberInput.value = settings.minKidsValue;
            this.updateDisplayData();
        });
    }

    setPeopleData = () => {
        const { peopleData } = this.settings;
        const { container, adultsNumberInput, kidsNumberInput } = this;

        const peopleModule = container.querySelector('.people-module');
        const kidsOptions = peopleModule.querySelector('.kids-options');
        const data = peopleData.split('/');
        const adultsNumber = parseInt(data[0]);
        const kidsNumber = parseInt(data[1]);
        const kidsData = data[2].split('-');
        this.peopleNumberToDisplay = adultsNumber + kidsNumber;

        for (let i = 0; i < kidsNumber; i++) {
            const singleChild = kidsData[i].split(',');
            const childAge = singleChild[0];
            const needBed = singleChild[1] === '1' ? true : false;
            if (!needBed) {
                this.peopleNumberToDisplay -= 1;
            }
            this.addKidsOptionsItem(kidsOptions, childAge, needBed);
        }

        adultsNumberInput.value = adultsNumber;
        kidsNumberInput.value = kidsNumber;
        this.updateDisplayData();
    }

    updateDisplayData = () => {
        this.mainInput.value = `${this.locale.numberOfPeople} ${this.peopleNumberToDisplay}`;
        if (this.mobileDisplay) {
            this.mobileDisplay.innerText = `${this.locale.numberOfPeople} ${this.peopleNumberToDisplay}`;
        }
    }

    getTuristsData = () => {
        const { container, adultsNumberInput } = this;
        const { kidsAgeForCheckbox } = this.settings;
        const adultsCount = parseInt(adultsNumberInput.value);
        const peopleModule = container.querySelector('.people-module');
        const kidsOptions = peopleModule.querySelector('.kids-options');
        const kidsOptionsItems = kidsOptions.querySelectorAll('.kids-options__item');

        let kidsCount = 0;
        let kidsForm = [];
        kidsOptionsItems.forEach(item => {
            const select = item.querySelector('select');

            if (select.value > kidsAgeForCheckbox) {
                kidsCount += 1;
                kidsForm.push(select.value, 1);
            } else {
                const checkbox = select.parentElement.parentElement.parentElement.querySelector('.kids-options__checkbox-container input[type="checkbox"]');

                if (checkbox.checked) {
                    kidsCount += 1;
                    kidsForm.push(select.value, 1);
                }
                else {
                    kidsForm.push(select.value, 0);
                }
            }
        });

        return {
            adults: adultsCount,
            kids: kidsOptionsItems.length,
            kidsOptions: kidsForm
        };
    }
}