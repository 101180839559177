import SearchModuleBase from './base.js';

export default class SearchFeaturesModule extends SearchModuleBase {
    container = document.getElementById('featuresContainer');
    selectedFeatures = [];
    featuresQuery = "";

    constructor(config, notification) {
        super(config, notification);
        this.bindFeatureSelect();
    }

    bindFeatureSelect = () => {
        const { container } = this;
        const items = container.querySelectorAll('label');

        items.forEach(item => {
            item.addEventListener('click', (e) => {
                if (e.srcElement.tagName != 'INPUT') {
                    return;
                }
                const checkbox = item.querySelector('[type="checkbox"]');
                const id = checkbox.value;
                const name = item.querySelector('span').innerHTML;
                const applyTo = checkbox.dataset.applyTo;
                const feature = { id, name, applyTo };

                if (checkbox.checked) {
                    if (!this.selectedFeatures.includes(feature.id)) {
                        this.selectedFeatures.push(feature);
                    }
                } else {
                    this.selectedFeatures = this.selectedFeatures.filter(value => {
                        return value.id !== feature.id;
                    });
                }

                this.updateFeatureInput(this.selectedFeatures);
                this.setFeaturesQuery();
            });
        });
    }

    updateFeatureInput = (features = null) => {
        if (features !== null) {
            this.selectedFeatures = [...new Set(features)];
        }
    }

    setFeaturesQuery = () => {
        let query = "?"
        let fFeatures = "fFeatures";
        let aFeatures = "aFeatures";
        this.featuresQuery = "";
        if (this.selectedFeatures.length > 0) {
            for (let i = 0; i < this.selectedFeatures.length; i++) {
                let _fa = "";
                if (this.selectedFeatures[i].applyTo === "Facility") {
                    _fa = fFeatures;
                }
                if (this.selectedFeatures[i].applyTo === "Apartment") {
                    _fa = aFeatures;
                }

                query += _fa + "=" + this.selectedFeatures[i].id + ";true";
                if (i < (this.selectedFeatures.length - 1)) {
                    query += "&";
                }
            }
            this.featuresQuery = query;
        }
    }

    getFeaturesQuery = () => {
        const items = this.container.querySelectorAll('label');
        this.selectedFeatures = [];
        items.forEach(item => {
            const checkbox = item.querySelector('[type="checkbox"]');
            const id = checkbox.value;
            const name = item.querySelector('span').innerHTML;
            const applyTo = checkbox.dataset.applyTo;
            const feature = { id, name, applyTo };

            if (checkbox.checked) {
                if (!this.selectedFeatures.includes(feature.id)) {
                    this.selectedFeatures.push(feature);
                }
            }

            this.updateFeatureInput(this.selectedFeatures);
            this.setFeaturesQuery();
        });
        return this.featuresQuery;
    }
}