import { tns } from "tiny-slider";
import SliderCounter from '../../utilities/slider-counter';

export default class OffersSection {
    slider = null;
    sliderCounter = null;

    constructor() {
        this.sliderCounter = new SliderCounter();
        this.init();
    }

    init = () => {
        this.slider = tns({
            preventScrollOnTouch: 'force',
            container: `#offersSlider`,
            items: 1,
            nav: false,
            mouseDrag: true,
            center: true,
            controls: false,
            swipeAngle: false,
            arrowKeys: true,
            disable: true,
            responsive: {
                600: {
                    items: 2,
                    disable: false,
                },
                900: {
                    items: 4,
                    disable: false,
                }
            }
        });

        if (this.slider !== undefined) {
            this.slider.events.on('indexChanged', (data, eventName) => {
                this.sliderCounter.add(data);
            });
        }

        document.getElementById('offersSliderPrev').addEventListener('click', () => {
            this.slider.goTo('prev');
        });

        document.getElementById('offersSliderNext').addEventListener('click', () => {
            this.slider.goTo('next');
        });
    }
}