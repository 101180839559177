export default class InputNumberFixer {

    /*
    * Fixer uniemożliwiający wpisywanie liter w numeryczne inputy
    */

    constructor() {
        this.init();
    }

    init = () => {
        document.querySelectorAll('input[type="number"]').forEach(input => {
            input.addEventListener('keypress', (e) => {
                const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|.|,)/);
                return !e.key.match(regex) && e.preventDefault();
            });
        });
    }
}