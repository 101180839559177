export default class FooterSection {
    constructor() {
        // this.bindContentToggle();
    }

    bindContentToggle = () => {
        const toggle = document.querySelector('.footer__container .toggle');
        const section2 = document.querySelector('.section2');
        const section3 = document.querySelector('.section3');
        const section4 = document.querySelector('.section4');

        toggle.addEventListener('click', () => {
            toggle.classList.toggle('active');
            section2.classList.toggle('active');
            section3.classList.toggle('active');
            section4.classList.toggle('active');
        });
    }
}