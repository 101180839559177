export default class Register {
    form = document.getElementById('formRegister');
    inputs = {
        name: this.form.querySelector('input[name="name"]'),
        surname: this.form.querySelector('input[name="surname"]'),
        email: this.form.querySelector('input[name="email"]'),
        phoneNumber: this.form.querySelector('input[name="phoneNumber"]'),
        submitButton: this.form.querySelector('input[type="submit"]')
    };
    validation = {
        name: false,
        surname: false,
        email: false,
        phoneNumber: false,
    };
    locale = {
        wrongRegisterDnsServer: "Address email is incorrect",
        wrongRegisterData: "Invalid register data.",
        wrongEmail: "Invalid email.",
        wrongPhoneNumber: "Invalid phone number.",
        filedRequied: "Required field.",
        phoneNumberTooShort: "Phone number too short."
    };
    errorElementClassName = 'input--error';
    emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    phoneRegex = /^[- +()]*[0-9][- +()0-9]{5,18}$/;

    constructor(config) {
        this.prepareConfig(config);
        this.bindValidateOnChangeInputs();
        this.bindFormSubmit();
    }

    prepareConfig = (config) => {
        if (typeof config.locale === 'object') {
            if (typeof config.locale.wrongRegisterDnsServer === 'string') {
                this.locale.wrongRegisterDnsServer = config.locale.wrongRegisterDnsServer;
            }

            if (typeof config.locale.wrongRegisterData === 'string') {
                this.locale.wrongRegisterData = config.locale.wrongRegisterData;
            }

            if (typeof config.locale.wrongEmail === 'string') {
                this.locale.wrongEmail = config.locale.wrongEmail;
            }

            if (typeof config.locale.wrongPhoneNumber === 'string') {
                this.locale.wrongPhoneNumber = config.locale.wrongPhoneNumber;
            }

            if (typeof config.locale.filedRequied === 'string') {
                this.locale.filedRequied = config.locale.filedRequied;
            }

            if (typeof config.locale.phoneNumberTooShort === 'string') {
                this.locale.phoneNumberTooShort = config.locale.phoneNumberTooShort;
            }
        }
    }

    bindFormSubmit = () => {
        const { form, validation } = this;

        form.addEventListener('submit', (e) => {
            try {
                e.preventDefault();

                if (validation.name && validation.surname && validation.email && validation.phoneNumber) {
                    this.register();
                } else {
                    this.validateEmail();
                    this.validateName();
                    this.validateSurname();
                    this.validatePhoneNumber();
                }
            } catch (error) {
                console.error(error);
            }
        });
    }

    register = async () => {
        const { form, inputs, locale } = this;

        let formData = '';
        formData += `Name=${encodeURIComponent(inputs.name.value)}&`;
        formData += `Surname=${encodeURIComponent(inputs.surname.value)}&`;
        formData += `Email=${encodeURIComponent(inputs.email.value)}&`;
        formData += `PhoneNumber=${encodeURIComponent(inputs.phoneNumber.value)}`;

        const response = await fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Requested': 'With: XMLHttpRequest'
            }
        });
        const res = await response.json();
        if (res === 0) {
            this.showFormSubmitError(locale.wrongRegisterData);
        } else if (res === -1) {
            this.showFormSubmitError(locale.wrongRegisterDnsServer);
        } else {
            const modalNewsletter = document.querySelector('[data-popup-modal="newsletter"]');
            modalNewsletter.querySelector('input[name="userId"]').value = res;
            modal.close('register1');
            try {
                if (glStatisticalCookies == true) {
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'sign_up'
                    });
                }
            }
            catch (e) {
                //            console.log(e);
            }
            modal.open('newsletter');

            //window.location.reload(true);
        }
    }

    bindValidateOnChangeInputs = () => {
        const { email, name, surname, phoneNumber } = this.inputs;

        email.addEventListener('change', () => {
            this.validateEmail();
        });

        name.addEventListener('change', () => {
            this.validateName();
        });

        surname.addEventListener('change', () => {
            this.validateSurname();
        });

        phoneNumber.addEventListener('change', () => {
            this.validatePhoneNumber();
        });
    }

    validateEmail = () => {
        const { emailRegex, locale } = this;
        const { email } = this.inputs;

        if (this.isset(email.value)) {
            if (emailRegex.test(email.value)) {
                this.validation.email = true;
                this.removeInputError(email);
            } else {
                this.validation.email = false;
                this.addInputError(email, locale.wrongEmail);
            }
        } else {
            this.addInputError(email, locale.filedRequied);
        }
    }

    validateName = () => {
        const { locale } = this;
        const { name } = this.inputs;

        if (this.isset(name.value)) {
            this.validation.name = true;
            this.removeInputError(name);
        } else {
            this.validation.name = false;
            this.addInputError(name, locale.filedRequied);
        }
    }

    validateSurname = () => {
        const { locale } = this;
        const { surname } = this.inputs;

        if (this.isset(surname.value)) {
            this.validation.surname = true;
            this.removeInputError(surname);
        } else {
            this.validation.surname = false;
            this.addInputError(surname, locale.filedRequied);
        }
    }

    validatePhoneNumber = () => {
        const { phoneRegex, locale } = this;
        const { phoneNumber } = this.inputs;

        if (this.isset(phoneNumber.value)) {
            if (phoneNumber.value.length >= 6) {
                if (phoneRegex.test(phoneNumber.value)) {
                    this.validation.phoneNumber = true;
                    this.removeInputError(phoneNumber);
                } else {
                    this.validation.phoneNumber = false;
                    this.addInputError(phoneNumber, locale.wrongPhoneNumber);
                }
            } else {
                this.validation.phoneNumber = false;
                this.addInputError(phoneNumber, locale.phoneNumberTooShort);
            }
        } else {
            this.addInputError(phoneNumber, locale.filedRequied);
        }
    }

    addInputError = (input, message) => {
        const inputContainer = input.parentElement;
        const element = inputContainer.querySelector(`.input__description`);
        if (element === null) {
            inputContainer.classList.add(this.errorElementClassName);
            input.parentElement.appendChild(this.getErrorElement(message));
        }
    }

    removeInputError = (input) => {
        const inputContainer = input.parentElement;
        const element = inputContainer.querySelector(`.input__description`);
        if (element !== null) {
            inputContainer.classList.remove(this.errorElementClassName);
            element.remove();
        }
    }

    getErrorElement = (message) => {
        const p = document.createElement('p');
        p.classList.add('input__description');
        p.innerHTML = message;

        return p;
    }

    showFormSubmitError = (message) => {
        const div = document.createElement('div');
        div.classList.add('form__main-error');

        const p = document.createElement('p');
        p.innerHTML = message;
        div.appendChild(p);

        this.form.appendChild(div);

        setTimeout(() => {
            div.classList.add('show');
        }, 100);

        setTimeout(() => {
            div.remove();
        }, 3000);
    }

    isset = (value) => {
        return !(!value || value === undefined || value === "" || value.length === 0 || value == null || value === null);
    }
}