require('./../../../jquery/daterangepicker');

import SearchModuleBase from './base.js';
import Moment from './../../../utilities/moment.js';

export default class SearchDatesModule extends SearchModuleBase {
    select = document.getElementById('datesSelect');
    input = document.getElementById('datepicker');
    container = document.getElementById('datesContainer');
    mobileDisplay = document.getElementById('searchDate');
    closeButton = null;
    datepicker = null;

    constructor(config, notification) {
        super(config, notification, "datesComponentDisabled");
        this.moment = new Moment();
        this.selectButton = this.container.querySelector('.button--primary');
        this.closeButton = this.container.querySelector('.close');
        this.input.disabled = this.disabled;
        this.initDateRangePicker();
        this.bindOpenDatesSelectModal();
        this.bindCloseDatesSelectModal();
    }

    initDateRangePicker = () => {
        this.datepicker = $('#datepicker');

        this.datepicker.daterangepicker({
            minDate: this.settings.minDate === "null" ? this.moment.m(new Date()) : this.settings.minDate,
            maxDate: this.settings.maxDate === "null" ? this.moment.m(new Date()).add(1, "year") : this.settings.maxDate,
            minSpan: this.settings.minSpan !== "null" ? {
                days: this.settings.minSpan,
            } : null,
            maxSpan: this.settings.maxSpan !== "null" ? {
                days: this.settings.maxSpan,
            } : null,
            autoApply: false,
            autoUpdateInput: false,
            applyButtonClasses: 'button button--primary',
            cancelButtonClasses: 'button button--tetriary',
            opens: 'right',
            parentEl: '.dates-module',
            locale: {
                weekLabel: '',
                daysOfWeek: this.locale.daysOfWeek,
                monthNames: this.locale.monthNames,
                cancelLabel: this.locale.cancelLabel,
                applyLabel: this.locale.applyLabel,
                daysLabelSingularNominative: this.locale.daysLabelSingularNominative,
                daysLabelPluralNominative: this.locale.daysLabelPluralNominative,
                daysLabelPluralGenitive: this.locale.daysLabelPluralGenitive,
                format: 'YYYY-MM-DD',
                descriptionFormat: 'YYYY-MM-DD',
                arrival: this.locale.arrival,
                departure: this.locale.departure,
                from: this.locale.from,
                to: this.locale.to,
            }
        });

        if (this.settings.startDate !== null) {
            $(this.datepicker).data('daterangepicker').setStartDate(this.settings.startDate);
        }

        if (this.settings.endDate !== null) {
            $(this.datepicker).data('daterangepicker').setEndDate(this.settings.endDate);
        }

        this.datepicker.on('showCalendar.daterangepicker', (ev, picker) => {
            $('.daterangepicker').css('display', 'flex');
        });

        this.datepicker.on('cancel.daterangepicker', (ev, picker) => {
            $('body').removeClass('no-overflow');
            $('#datesContainer').removeClass('search-container--open');
            $('#datesContainer').addClass('search-container--close');
        });

        this.datepicker.on('apply.daterangepicker', (ev, picker) => {
            if (this.mobileDisplay) {
                this.mobileDisplay.innerText = this.input.value;
            }
            $('body').removeClass('no-overflow');
            $('#datesContainer').removeClass('search-container--open');
            $('#datesContainer').addClass('search-container--close');
        });
    }

    bindOpenDatesSelectModal = () => {
        const { input } = this;

        input.addEventListener('click', (e) => {
            if (this.disabled) {
                this.notification.show(this.notification.types.error, this.locale.inputCannotBeEdited);
            } else {
                this.openModalHandler();
            }
        });
    }

    bindCloseDatesSelectModal = () => {
        const { container, closeButton } = this;

        [container, closeButton].forEach(element => {
            element.addEventListener('click', (e) => {
                if (e.target == container || e.target.parentElement == closeButton) {
                    this.closeModalHandler();
                }
            });
        });
    }

    openModalHandler = () => {
        if (document.body.clientWidth < 901) {
            document.body.classList.add('no-overflow');
            document.documentElement.classList.add('no-overflow');
        }
        this.container.classList.remove('search-container--close');
        this.container.classList.add('search-container--open');
        $(this.input).click();
    }

    closeModalHandler = () => {
        document.body.classList.remove('no-overflow');
        document.documentElement.classList.remove('no-overflow');
        this.container.classList.remove('search-container--open');
        this.container.classList.add('search-container--close');
    }

    getDateRangeObject = () => {
        const inputValue = this.input.value;
        const range = inputValue.split(' - ');

        return {
            start: range[0],
            finish: range[1]
        }
    }
}