import BaseView from './utilities/baseView.js';
import Search from './modules/ApartmentSearch/search.js';
import RecommendedsSection from './modules/Home/recommendedSection.js';
import FooterSection from './modules/Home/footerSection.js';
import SurveyModalSection from './modules/Home/surveyModalSection.js';
import ChangePasswordSection from './modules/Home/changePasswordSection.js';
import OffersSection from './modules/Home/offersSection.js';

class HomeView extends BaseView {
    search = null;
    mapSection = null;
    newsSection = null;
    recommendedSection = null;
    offersSection = null;
    footerSection = null;
    surveyModalSection = null;

    accountActivatedModal = 'account-activated';
    accountAlreadyActiveModal = 'account-already-active';
    accountExpiredModal = 'account-expired';
    reservationTimeExpireModal = 'reservation-time-expire';
    reservationConfirmationModal = 'reservation-confirmation';
    reservationCreatedTmpModal = 'reservation-created-tmp';
    reservationCreatedFailModal = 'reservation-created-fail';
    logoutModal = 'logoutModal';
    apartmentIsNotFreeModal = 'apartment-is-no-free';
    resetPasswordChangeModal = 'resetPasswordChange';
    newsletterRegistrationSuccessModal = 'nletter-registration-success';
    surveyFirstModal = 'survey-first';
    surveyThanksModal = 'survey-thanks';
    promotionNotActiveModal = 'promotion-not-active';

    constructor() {
        super(homeConfig);
        this.search = new Search(apartmentSearchConfig, this.notification, this.loader, false, false);
        this.recommendedSection = new RecommendedsSection();
        this.offersSection =  new  OffersSection();
        this.footerSection = new FooterSection();
        this.surveyModalSection = new SurveyModalSection(this.notification, this.settings, this.locale);
        this.changePasswordSection = new ChangePasswordSection(this.notification, this.settings, this.locale);

        this.modalsStateManagement();
    }
    modalsStateManagement = () => {
        if (this.settings("showAccountActivatedModal")) {
            modal.open(this.accountActivatedModal);
        }
        if (this.settings("showAccountAlreadyActiveModal")) {
            modal.open(this.accountAlreadyActiveModal);
        }
        if (this.settings("showAccountExpiredModal")) {
            modal.open(this.accountExpiredModal);
        }
        if (this.settings("showReservationTimeExpireModal")) {
            modal.open(this.reservationTimeExpireModal);
        }
        if (this.settings("showReservationConfirmationModal")) {
            if (this.settings("reservationConfirmationId") !== '' && this.settings("reservationConfirmationGuid") !== '') {
                this.setDataToConfirmationModal();
            }
        }
        if (this.settings("showReservationCreatedTmpModal")) {
            modal.open(this.reservationCreatedTmpModal);
        }
        if (this.settings("reservationCreatedFailModal")) {
            modal.open(this.reservationCreatedFailModal);
        }
        if (this.settings("showApartmentIsNotFreeModal")) {
            modal.open(this.apartmentIsNotFreeModal);
        }
        if (this.settings("logoutModal")) {
            modal.open(this.logoutModal);
        }
        if (this.settings("newsletterRegistrationSuccessModal")) {
            modal.open(this.newsletterRegistrationSuccessModal);
            // TODO
            try {
                if (glStatisticalCookies == true) {
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'form_submitted_newsletter'
                    });
                }
            }
            catch (e) {
                //            console.log(e);
            }
        }
        if (this.settings("showResetPasswordModal")) {
            modal.open(this.resetPasswordChangeModal);
        }
        if (this.settings("surveyFirstModal")) {
            modal.open(this.surveyFirstModal);
        }
        if (this.settings("surveyThanksModal")) {
            modal.open(this.surveyThanksModal);
        }
        if (this.settings("promotionNotActiveModal")) {
            modal.open(this.promotionNotActiveModal);
        }
    }

    setDataToConfirmationModal = async () => {
        const container = document.querySelector('[data-popup-modal="reservation-confirmation"]');
        const formData = `Id=${this.settings("reservationConfirmationId")}&Token=${this.settings("reservationConfirmationGuid")}`;

        const response = await fetch(`/Reservation/ConfirmData`, {
            method: 'POST',
            body: formData,
            headers: {
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Requested': 'With: XMLHttpRequest'
            }
        });
        const res = await response.json();
        // ! TODO: WTF?! Do refaktora całość poniżej.
        const parsedDateFrom = res.DateFrom.split('(')[1].split(')')[0];
        const parsedDateTo = res.DateTo.split('(')[1].split(')')[0];
        const dateFrom = new Date(Number(parsedDateFrom));
        const dateTo = new Date(Number(parsedDateTo));

        container.querySelector('.reservationDataFrom').innerHTML = this.dateToYMD(dateFrom);
        container.querySelector('.reservationDataTo').innerHTML = this.dateToYMD(dateTo);
        container.querySelector('.reservationApartmentName').innerHTML = res.ApartmentName;
        container.querySelector('.reservationFacilityName').innerHTML = res.FacilityName;
        container.querySelector('.reservationStreet').innerHTML = res.Street;

        modal.open(this.popupModal.reservationConfirmation);
    }

    dateToYMD = (date) => {
        const d = date.getDate();
        const m = date.getMonth() + 1; //Month from 0 to 11
        const y = date.getFullYear();

        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
}

new HomeView();