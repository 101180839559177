import Notification from './notification.js';
import ValidationException from './../exceptions/validationException.js';

export default class Validation {
    notification = null;

    settings = {
        addMessageUnderInput: false,
        url: {
            checkUserEmailExists: null,
            checkUserPasswordMatch: null
        }
    };

    regex = {
        inputText: /^.{,40}$/,
        email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,40})$/,
        phoneNumber: /^[- +()]*[0-9][- +()0-9]{5,18}$/,
        taxNumber: /^[0-9\-]{10,20}$/,
        postCode: /^[A-Za-z0-9-]{4,8}$/
    };

    locale = {
        emptyName: 'Name filed is required!',
        emptySurname: 'Surname filed is required!',
        wrongEmail: 'Incorrect email!',
        emailNotActive: 'Email not active!',
        wrongPhoneNumber: 'Incorrect phone number!',
        wrongTaxNumber: 'Wrong tax number!',
        emptyCompanyName: 'Empty company name!',
        emptyHomeNumber: 'Empty home number!',
        emptyCity: 'Empty city!',
        emptyStreet: 'Empty street!',
        wrongPostCode: 'Wrong post code!',
        emptyPassword: 'Password required!',
        passwordsNotTheSame: 'The passwords do not match!',
        userWrongPassword: 'The password provided is incorrect.',
        filedRequied: "Required field.",
        updatePasswordSuccess: 'Updated password',
        updatePasswordError: 'The new password is too weak',
        buttonViewMore: "See more",
        buttonViewLess: "See less",
        wrongRegisterDnsServer: 'The provided e-mail domain does not exist.',
        regulationsAcceptanceCheck: "You must accept the terms and conditions.",
        emailAgreementCheck: "You must check email agreement.",
        phoneNumberAgreementCheck: "You must check phone number agreement."
    };

    isValidate = true;

    constructor(config) {
        this.notification = new Notification();
        this.prepareConfig(config);
    }

    prepareConfig(config) {
        if (typeof config.settings === 'object') {
            for (const [key, value] of Object.entries(this.settings.url)) {
                if (typeof config.settings.url[key] === 'string') {
                    this.settings.url[key] = config.settings.url[key];
                }
            }
        }

        if (typeof config.locale === 'object') {
            for (const [key, value] of Object.entries(this.locale)) {
                if (typeof config.locale[key] === 'string') {
                    this.locale[key] = config.locale[key];
                }
            }
        }
    }

    validateName = (name, required = true) => {
        const isset = required ? this.isset(name.value) : true;

        if (!isset || name.value.length > 40) {
            this.addInputError(name, this.locale.emptyName);
        } else {
            this.removeInputError(name);
        }
    }

    validateSurame = (surname, required = true) => {
        const isset = required ? this.isset(surname.value) : true;

        if (!isset || surname.value.length > 40) {
            this.addInputError(surname, this.locale.emptySurname);
        } else {
            this.removeInputError(surname);
        }
    }

    validateEmailOnly = (email, required = true) => {
        const { regex } = this;
        const isset = required ? this.isset(email.value) : true;

        if (isset && regex.email.test(email.value) && email.value.length <= 40) {
            this.removeInputError(email);
        } else {
            this.addInputError(email, this.locale.wrongEmail);
        }
    }

    validateEmail = (email, requireLogin = true) => {
        const { url } = this.settings;
        const { regex } = this;

        return new Promise(async (resolve, reject) => {
            if (this.isset(email.value) && regex.email.test(email.value)) {
                if (requireLogin) {
                    const formData = `email=${encodeURIComponent(email.value)}`;
                    const response = await fetch(url.checkUserEmailExists, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                            'X-Requested': 'With: XMLHttpRequest'
                        }
                    });
                    const res = await response.text();
                    this.removeInputError(email);
    
                    switch (res) {
                        case '0':
                            resolve();
                            break;
                        case '1':
                            modal.open('login');
                            reject(new ValidationException(this.locale.userNotLogin));
                            break;
                        case '2':
                            reject(new ValidationException(this.locale.emailNotActive));
                            break;
                        case '3':
                            reject(new ValidationException(this.locale.wrongRegisterDnsServer));
                            break;
                        default:
                            reject(new ValidationException(this.locale.wrongEmail));
                            break;
                    }
                }

                resolve();
            } else {
                this.addInputError(email, this.locale.wrongEmail);
            }

            resolve();
        });
    }

    validatePhoneNumber = (phoneNumber, required = true) => {
        const { regex } = this;
        const isset = required ? this.isset(phoneNumber.value) : true;
        const correct = regex.phoneNumber.test(phoneNumber.value);

        if (required) {
            if (isset && correct) {
                this.removeInputError(phoneNumber);
            } else {
                this.addInputError(phoneNumber, this.locale.wrongPhoneNumber);
            }
        } else {
            if (isset && !correct) {
                this.addInputError(phoneNumber, this.locale.wrongPhoneNumber);
            } else {
                this.removeInputError(phoneNumber);
            }
        }
    }

    validateCompanyName = (companyName, required = true) => {
        const isset = required ? this.isset(companyName.value) : true;

        if (!isset || companyName.value.length > 256) {
            this.addInputError(companyName, this.locale.emptyCompanyName);
        } else {
            this.removeInputError(companyName);
        }
    }

    validateStreet = (street, required = true) => {
        const isset = required ? this.isset(street.value) : true;

        if (!isset || street.value.length > 40) {
            this.addInputError(street, this.locale.emptyStreet);
        } else {
            this.removeInputError(street);
        }
    }

    validateHomeNumber = (homeNumber, required = true) => {
        const isset = required ? this.isset(homeNumber.value) : true;

        if (!isset || homeNumber.value.length > 40) {
            this.addInputError(homeNumber, this.locale.emptyHomeNumber);
        } else {
            this.removeInputError(homeNumber);
        }
    }

    validatePostCode = (postCode, required = true) => {
        const { regex } = this;
        const isset = required ? this.isset(postCode.value) : true;

        if (isset && postCode.value.length > 0) {
            if (regex.postCode.test(postCode.value)) {
                this.removeInputError(postCode);
            } else {
                this.addInputError(postCode, this.locale.wrongPostCode);
            }
        } else {
            this.removeInputError(postCode);
        }
    }

    validateCity = (city, required = true) => {
        const isset = required ? this.isset(city.value) : true;

        if (!isset || city.value.length > 40) {
            this.addInputError(city, this.locale.emptyCity);
        } else {
            this.removeInputError(city);
        }
    }

    validateTaxNumber = (taxNumber, required = true) => {
        const { regex } = this;
        const isset = required ? this.isset(taxNumber.value) : true;

        if (isset && taxNumber.value.length > 0) {
            if (regex.taxNumber.test(taxNumber.value)) {
                this.removeInputError(taxNumber);
            } else {
                this.addInputError(taxNumber, this.locale.wrongTaxNumber);
            }
        } else {
            this.removeInputError(taxNumber);
        }
    }

    validateOldPassword = (password) => {
        const { url } = this.settings;

        return new Promise(async (resolve, reject) => {
            if (this.isset(password.value)) {
                const formData = `Password=${encodeURIComponent(password.value)}`;

                const response = await fetch(url.checkUserPasswordMatch, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-Requested': 'With: XMLHttpRequest'
                    }
                });
                const res = await response.json();
    
                if (res) {
                    this.removeInputError(password);
                } else {
                    this.addInputError(password, this.locale.userWrongPassword);
                }
            } else {
                this.addInputError(password, this.locale.emptyPassword);
            }

            resolve();
        });
    }

    validatePassword = (password) => {
        if (!this.isset(password.value)) {
            this.addInputError(password, this.locale.emptyPassword);
        } else {
            this.removeInputError(password);
        }
    }

    validateRepeatPassword = (newPassword, repeatPassword) => {
        if (!this.isset(repeatPassword.value)) {
            this.addInputError(repeatPassword, this.locale.emptyPassword);
        } else {
            if (newPassword.value !== repeatPassword.value) {
                this.addInputError(repeatPassword, this.locale.passwordsNotTheSame);
            } else {
                this.removeInputError(repeatPassword);
            }
        }
    }

    validateRegulationsAcceptance = (regulationsAcceptance) => {
        if (regulationsAcceptance.checked) {
            this.removeInputError(regulationsAcceptance);
        } else {
            this.addInputError(regulationsAcceptance, this.locale.regulationsAcceptanceCheck);
        }
    }

    validateEmailAgreement = (emailAgreement) => {
        if (emailAgreement.checked) {
            this.removeInputError(emailAgreement);
        } else {
            this.addInputError(emailAgreement, this.locale.emailAgreementCheck);
        }
    }

    validatePhoneNumberAgreement = (phoneNumberAgreement) => {
        if (phoneNumberAgreement.checked) {
            this.removeInputError(phoneNumberAgreement);
        } else {
            this.addInputError(phoneNumberAgreement, this.locale.phoneNumberAgreementCheck);
        }
    }

    addInputError = (input, message) => {
        if (input !== null) {
            input.parentElement.querySelector('.input__description')?.remove();
            switch (input.type) {
                case 'checkbox':
                    input.classList.add('error');
                    break;
            
                default:
                    input.parentElement.classList.add('input--error');
                    break;
            }
            input.parentElement.appendChild(this.getErrorElement(message));
            this.isValidate = false;
        }
    }

    removeInputError = (input) => {
        input.parentElement.querySelector('.input__description')?.remove();
        
        switch (input.type) {
            case 'checkbox':
                input.classList.remove('error');
                break;
        
            default:
                input.parentElement.classList.remove('input--error');
                break;
        }
    }

    getErrorElement = (message) => {
        const p = document.createElement('p');
        p.classList.add('input__description');
        p.innerHTML = message;

        return p;
    }

    isset = (value) => {
        return !(!value || value === undefined || value === "" || value.length === 0 || value == null || value === null);
    }
}