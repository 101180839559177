import moment from 'moment';

export default class Moment {
    constructor() {
        this.m = moment;

        switch (globalConfig.language) {
            case "de_DE":
                moment.locale('de');
                break;

            case "en_GB":
                moment.locale('en');
                break;
        
            default:
                moment.locale('pl');
                break;
        }
    }
}