export default class MainLoader {

    constructor(locale) {
        this.locale = locale;
        this.create();
    }

    create = () => {
        this.loader = document.createElement('div');
        this.loader.classList.add('main-loader');

        const container = document.createElement('div');
        container.classList.add('main-loader__container');
        this.loader.appendChild(container);

        const spinner = document.createElement('div');
        spinner.classList.add('spinner-border');

        const text = document.createElement('span');
        text.innerText = this.locale.pleaseWait;
        
        container.appendChild(spinner);
        container.appendChild(text);
        document.body.appendChild(this.loader);
    }

    show = () => {
        this.loader.classList.add('active');
        document.body.style.overflow = "hidden";
    }

    hide = () => {
        this.loader.classList.remove('active');
        document.body.style.overflow = null;
    }
}