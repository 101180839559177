export default class Switcher {

    component = null;
    buttons = [];
    containers = [];

    constructor(componentId) {
        this.component = document.getElementById(componentId);
        this.init();
        this.bindButtons();
    }

    init = () => {
        this.buttons = this.component.querySelectorAll('.switcher__buttons p');
        this.containers = this.component.querySelectorAll('.switcher__container .switcher-item');
        this.activateDefault();
    }

    activateDefault = () => {
        if (this.component.querySelectorAll('.switcher__buttons p.active').length === 0) {
            this.buttons[0].classList.add('active');
        }

        if (this.component.querySelectorAll('.switcher__container .switcher-item.active').length === 0) {
            this.containers[0].classList.add('active');
        }
    }

    bindButtons = () => {
        this.buttons.forEach((button, index) => {
            button.addEventListener('click', () => {
                this.removeActiveClass();
                button.classList.add('active');
                this.containers[index].classList.add('active');
            });
        });
    }

    removeActiveClass = () => {
        this.component.querySelectorAll('.switcher__buttons p.active').forEach(button => {
            button.classList.remove('active');
        });
        this.component.querySelectorAll('.switcher__container .switcher-item.active').forEach(item => {
            item.classList.remove('active');
        });
    }
}