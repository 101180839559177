import ValidationException from './../exceptions/validationException';
import Notification from './notification.js';
import Validation from './validation.js';

export default class ResetPassword {
    notification = new Notification();
    validation = null;

    form = document.getElementById('resetPasswordForm');
    formData = {
        email: this.form.querySelector('input[name="email"]')
    };
    isValidate = false;
    locale = {
        wrongEmail: "Invalid email.",
        filedRequied: "Required field.",
        resetPasswordSuccess: 'Reset password success',
        resetPasswordError: 'Reset password error'
    };
    errorElementClassName = 'input-container__error';
    emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    constructor(config) {
        this.validation = new Validation(config);
        this.prepareConfig(config);
        this.bindSubmitButton();
    }

    prepareConfig = (config) => {
        if (typeof config.locale === 'object') {
            for (const [key, value] of Object.entries(this.locale)) {
                if (typeof config.locale[key] === 'string') {
                    this.locale[key] = config.locale[key];
                }
            }
        }
    }

    validateForm = () => {
        const { formData } = this;
        this.validation.isValidate = true;

        return new Promise(async (resolve, reject) => {
            try {
                await this.validation.validateEmail(formData.email, false);
            } catch (error) {
                if (error instanceof ValidationException) {
                    this.validation.isValidate = false;
                    this.notification.show(this.notification.types.error, error.message);
                }
            } finally {
                resolve();
            }
        });
    }

    bindSubmitButton = () => {
        const { form, formData, locale, notification } = this;

        form.addEventListener('submit', async (e) => {
            e.preventDefault();
            await this.validateForm();

            if (this.validation.isValidate) {
                const data = `email=${encodeURIComponent(formData.email.value.trim())}&language=${document.documentElement.lang}`;
                const url = `${form.action}?${data}`;
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-Requested': 'With: XMLHttpRequest'
                    }
                });
                const res = await response.json();

                if (res) {
                    modal.close('resetPassword');
                    notification.show(notification.types.success, locale.resetPasswordSuccess);
                } else {
                    notification.show(notification.types.error, locale.resetPasswordError);
                }
            }
        });
    }
}