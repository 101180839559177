export default class NewsletterModalSection {
    notification = null;

    newsletterModule = {
        form: document.querySelector('.newsletter-form'),
        buttonSubmit: null,
        buttonCancel: null,
        inputCheckbox: null,
        userId: 0
    }

    settings = {
        popupModal: {
            newsletter: 'newsletter',
        },
        url: {
            newsletter: null,
        },
    }

    locale = {
        newsletterSuccess: 'Successfully subscribed to the newsletter!',
        newsletterError: 'There was an unexpected problem subscribing to the newsletter.',
    }

    constructor(config, notification) {
        this.prepareConfig(config);
        this.notification = notification;
        this.prepareNewsletterModule();
    }

    prepareConfig = (config) => {
        if (typeof config.settings === 'object') {
            for (const [key, value] of Object.entries(this.settings)) {
                if (typeof config.settings[key] === 'string' || typeof config.settings[key] === 'boolean' || typeof config.settings[key] === 'object') {
                    this.settings[key] = config.settings[key];
                }
            }
        }

        if (typeof config.locale === 'object') {
            for (const [key, value] of Object.entries(this.locale)) {
                if (typeof config.locale[key] === 'string') {
                    this.locale[key] = config.locale[key];
                }
            }
        }
    }

    prepareNewsletterModule = () => {
        const { form } = this.newsletterModule;

        this.newsletterModule.buttonSubmit = form.querySelector('.newsletter-form__buttons .button--primary');
        this.newsletterModule.buttonCancel = form.querySelector('.newsletter-form__buttons .button--secondary');
        this.newsletterModule.inputCheckbox = form.querySelector('input[type="checkbox"]');
        // ! Notatka na przyszłość: userId w tym inpucie jest ustawiane w pliku assets\src\js\utilities\register.js, nie powinno tak być, ale "ktoś" tak zrobił i zmiana wymagałaby refaktoru backendu
        this.newsletterModule.userId = form.querySelector('input[name="userId"]');

        this.bindNewsletterSubmitButton();
        this.bindNewsletterCancelButton();
    }

    bindNewsletterSubmitButton = () => {
        const { buttonSubmit, inputCheckbox, userId } = this.newsletterModule;
        const { url, popupModal } = this.settings;
        const { notification, locale } = this;

        buttonSubmit.addEventListener('click', async e => {
            e.preventDefault();
            const formData = `MarketingAgreement=${inputCheckbox.checked}&UserId=${userId.value}`;
            const response = await fetch(url.newsletter, {
                method: 'POST',
                body: formData,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'X-Requested': 'With: XMLHttpRequest'
                }
            });
            const res = await response.json();

            // TODO: sprawdzić poprawność reponse
            if (res) {
                modal.close(popupModal.newsletter);
                try {
                    if (glStatisticalCookies == true) {
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'form_submitted_newsletter'
                        });
                    }
                }
                catch (e) {
                    //            console.log(e);
                }
                this.newsletterModule.inputCheckbox.checked = false;
                notification.show(notification.types.success, locale.newsletterSuccess);
            } else {
                notification.show(notification.types.error, locale.newsletterError);
            }
        });
    }

    bindNewsletterCancelButton = () => {
        const { buttonCancel } = this.newsletterModule;
        const { popupModal } = this.settings;

        buttonCancel.addEventListener('click', () => {
            modal.close(popupModal.newsletter);
            this.newsletterModule.inputCheckbox.checked = false;
        });
    }

    dateToYMD = (date) => {
        const d = date.getDate();
        const m = date.getMonth() + 1; //Month from 0 to 11
        const y = date.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
}