import 'bootstrap-select';

export default class Selectpicker {

    constructor() {
        this.init();
    }

    init = () => {
        $.fn.selectpicker.Constructor.DEFAULTS.liveSearch = false;
        $.fn.selectpicker.Constructor.DEFAULTS.size = 5;
    }

    setValue = (select, value) => {
        select.value = value;
        $(select).selectpicker('refresh');
    }

    clear = (select) => {
        select.selectedIndex = 0;
        $(select).selectpicker('refresh');
    }
}