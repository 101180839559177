import Switcher from './switcher.js';

export default class CookieModal {

    constructor() {
        this.cookieSwitcher = new Switcher('cookieSwitcher');
        this.init();
        this.bindAcceptCookiesButtons();
        this.bindRejectCookiesButtons();
        this.bindSettingsCookiesButtons();
        this.bindShowDetailsContainerButtons();
    }

    init = () => {
        let showCookiesmodal = true;
        let marketingCookies = 0;
        let statisticalCookies = 0;
        let functionalCookies = 1;
        let statisticalCookiesFound = false;
        let marketingCookiesFound = false;
        let functionalCookiesFound = false;
        let cookies = document.cookie.split(/; */);

        for (var i = 0; i < cookies.length; i++) {
            if (cookies[i].split("=")[0] == "showCookiesModal" && cookies[i].split("=")[1] == "false") {
                modal.close('cookies');
                showCookiesmodal = false;
            }
            if (cookies[i].split("=")[0] == "statisticalCookies") {
                if (cookies[i].split("=")[1] == "false") {
                    statisticalCookies = 0;
                } else {
                    statisticalCookies = 1;
                }
                statisticalCookiesFound = true;
            }
            if (cookies[i].split("=")[0] == "marketingCookies") {
                if (cookies[i].split("=")[1] == "false") {
                    marketingCookies = 0;
                } else {
                    marketingCookies = 1;
                }
                marketingCookiesFound = true;
            }
            if (cookies[i].split("=")[0] == "functionalCookies") {
                if (cookies[i].split("=")[1] == "false") {
                    functionalCookies = 0;
                } else {
                    functionalCookies = 1;
                }
                functionalCookiesFound = true;
            }
        }
        var showCookiesmodalprev = showCookiesmodal;
        if (showCookiesmodalprev == false && statisticalCookiesFound == false ) {
            statisticalCookies = 1;
        }
        if (showCookiesmodalprev == false && marketingCookiesFound == false) {
            marketingCookies = 1;
        }
        if (showCookiesmodalprev == false && functionalCookiesFound == false) {
            functionalCookies = 1;
        }
        if (showCookiesmodal) {
            document.querySelector('#statistical-cookie-checkbox').checked = statisticalCookies;
            document.querySelector('#marketing-cookie-checkbox').checked = marketingCookies;
            document.querySelector('#functional-cookie-checkbox').checked = functionalCookies;
            modal.open('cookies');
        }
        else {
            this.setCookieTypeValues(statisticalCookies, marketingCookies, functionalCookies);
        }
    }

    bindAcceptCookiesButtons = () => {
        const buttons = document.querySelectorAll('.cookies-modal .accept-all');

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                this.acceptAllCookiesPopup();
            });
        });
    }

    bindRejectCookiesButtons = () => {
        const buttons = document.querySelectorAll('.cookies-modal .reject-all');

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                this.noAcceptance();
            });
        });
    }

    bindSettingsCookiesButtons = () => {
        const buttons = document.querySelectorAll('.cookies-modal .save-settings');

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                this.saveCookiesSettings();
            });
        });
    }

    bindShowDetailsContainerButtons = () => {
        const buttons = document.querySelectorAll('.cookies-modal .show-details');

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                document.querySelector('.switcher__buttons p:nth-child(2)').click();
            });
        });
    }

    setShowCookiesModalCookie = () => {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        document.cookie = "showCookiesModal=false; expires=" + date.toUTCString() + "; path= /;Secure;SameSite=None; Partitioned;";
    }

    acceptAllCookiesPopup = () => {
        modal.close('cookies');
        this.setShowCookiesModalCookie();
        this.setCookieTypeValues(1, 1, 1);
    }
    
    noAcceptance = () => {
        modal.close('cookies');
        this.setShowCookiesModalCookie();
        this.setCookieTypeValues(0, 0, 1);
    }

    saveCookiesSettings = () => {
        modal.close('cookies');
        this.setShowCookiesModalCookie();
        var stat = 0;
        if (document.querySelector('#statistical-cookie-checkbox').checked) {
            stat = 1;
        }
        var mark = 0;
        if (document.querySelector('#marketing-cookie-checkbox').checked) {
            mark = 1;
        }
        var func = 0;
        if (document.querySelector('#functional-cookie-checkbox').checked) {
            func = 1;
        }
        this.setCookieTypeValues(stat, mark, func);
    }

    setCookieTypeValues = (statistical, marketing, functional) => {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        var statisticalStatus = 'granted';
        var statisticalVal = "true";
        var marketingStatus = 'granted';
        var marketingVal = "true";
        var functionalStatus = 'granted';
        var functionalVal = "true";
        if (statistical == 0) {
            statisticalVal = "false";
            statisticalStatus = 'denied';
        }
        if (marketing == 0) {
            marketingVal = "false";
            marketingStatus = 'denied';
        }
        if (functional == 0) {
            functionalVal = "false";
            functionalStatus = 'denied';
        }
        document.cookie = "statisticalCookies=" + statisticalVal + ";expires=" + date.toUTCString() + "; path= /;Secure;SameSite=None; Partitioned;";
        document.cookie = "marketingCookies=" + marketingVal + ";expires=" + date.toUTCString() + "; path= /;Secure;SameSite=None; Partitioned;";
        document.cookie = "functionalCookies=" + functionalVal + ";expires=" + date.toUTCString() + "; path= /;Secure;SameSite=None; Partitioned;";
        try {
            gtag('consent', 'update', {
                analytics_storage: statisticalStatus,
                ad_storage: marketingStatus,
                ad_user_data: marketingStatus,
                ad_personalization: marketingStatus,
                functionality_storage: functionalStatus,
                personalization_storage: "denied",
                security_storage: "granted",
            });
        }
        catch (e) {
            //            console.log(e);
        }
        window.dataLayer = window.dataLayer || [];

        function dlPush(ev) {
            dataLayer.push(ev)
        }
        window.ABAUserdataLayer = window.ABAUserdataLayer || [];
        while (window.ABAUserdataLayer.length) {
            let ev = window.ABAUserdataLayer.shift();
            dlPush(ev);
        }

        dlPush({
            'event': 'cookie_consent_update',
        });
        if (statistical == 1) {
            dlPush({
                'event': 'cookie_consent_analytics',
            });
        }
        if (marketing == 1) {
            dlPush({
                'event': 'cookie_consent_ad',
            });
        }
        if (functional == 1) {
            dlPush({
                'event': 'cookie_consent_functionality',
            });
        }
        window.ABAdataLayer = window.ABAdataLayer || [];
        function dlUpdateCallback() {
            while (window.ABAdataLayer.length) {
                let ev = window.ABAdataLayer.shift();
                if (statistical == 1) {
                    dlPush(ev);
                }
            }
        }
        dlUpdateCallback();
//        setInterval(dlUpdateCallback, 1000);
    }

    sendCookiesData = async () => {
        let postData = ``;
        ratingPktSum += ratingPkt[type];
        const response = await fetch("", {
            method: 'POST',
            body: postData,
            headers: {
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Requested': 'With: XMLHttpRequest'
            }
        });

    }

    showCookiesSettingsPopup = () => {
        const cookiesettingsdiv = document.querySelector('#cookie_settings');

        if (cookiesettingsdiv.classList.contains('hidden')) {
            cookiesettingsdiv.classList.remove('hidden');
        } else {
            cookiesettingsdiv.classList.add('hidden');
        }
    }
}