require('regenerator-runtime');
require('popper.js');
require('jquery');
require('bootstrap');

import CookieModal from './cookieModal.js';
import InputNumberFixer from './inputNumberFixer.js';
import Login from './login.js';
import Logout from './logout.js';
import MainLoader from './mainLoader.js';
import Modal from './modal.js';
import Navigation from './navigation.js';
import Notification from './notification.js';
import Register from './register.js';
import ResetPassword from './reset-password.js';
import NewsletterModalSection from '../modules/Home/newsletterModalSection.js';

export default class BaseView {
    notification = null;
    localeData = {};
    settingsData = {};

    constructor(config, isStandaloneView = false, disableCookieModal = false) {
        window.modal = new Modal();
        this.prepareConfig(config);
        new InputNumberFixer();
        $('[data-toggle="tooltip"]').tooltip();
        this.notification = new Notification();
        this.loader = new MainLoader(baseConfig.locale);

        if (!disableCookieModal) {
            this.cookieModal = new CookieModal();
        }
        if (!isStandaloneView) {
            this.navigation = new Navigation();
            this.login = new Login(loginConfig);
            this.logout = new Logout(loginConfig);
            this.register = new Register(registerConfig);
            this.resetPassword = new ResetPassword(resetPasswordConfig);
            this.newsletterModalSection = new NewsletterModalSection(newsletterConfig, this.notification);
        }
    }

    prepareConfig = (config) => {
        for (const [key, value] of Object.entries(config.settings)) {
            this.settingsData[key] = config.settings[key];
        }

        for (const [key, value] of Object.entries(config.locale)) {
            this.localeData[key] = config.locale[key];
        }
    }

    locale = (key) => {
        if (this.localeData[key] !== undefined) {
            return this.localeData[key];
        } else {
            return `No translation for key "${key}"!`;
        }
    }

    settings = (key) => {
        if (this.settingsData[key] !== undefined) {
            return this.settingsData[key];
        } else {
            console.error(`No setting for key "${key}"!`);
        }
    }
}