export default class Login {
    form = document.getElementById('formLogin');
    resetPasswordLink = this.form.querySelector(`[data-modal-trigger="resetPassword"]`);
    registerButton = this.form.querySelector(`[data-modal-trigger="register1"]`);
    inputs = {
        email: this.form.querySelector('input[name="email"]'),
        password: this.form.querySelector('input[name="password"]'),
        rememberMe: this.form.querySelector('input[name="remeberMe"]'),
        submitButton: this.form.querySelector('input[type="submit"]')
    };
    validation = {
        email: false,
        password: false
    };
    locale = {
        wrongLoginData: "Invalid email address or password.",
        inactiveAccount: "Your account is inactive.",
        wrongEmail: "Invalid email.",
        filedRequied: "Required field."
    };
    errorElementClassName = 'input--error';
    emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    constructor(config) {
        this.prepareConfig(config);
        this.bindValidateOnChangeInputs();
        this.bindFormSubmit();
        this.loadLoginCookieData();
    }

    prepareConfig = (config) => {
        if (typeof config.locale === 'object') {
            if (typeof config.locale.wrongLoginData === 'string') {
                this.locale.wrongLoginData = config.locale.wrongLoginData;
            }

            if (typeof config.locale.inactiveAccount === 'string') {
                this.locale.inactiveAccount = config.locale.inactiveAccount;
            }

            if (typeof config.locale.wrongEmail === 'string') {
                this.locale.wrongEmail = config.locale.wrongEmail;
            }

            if (typeof config.locale.filedRequied === 'string') {
                this.locale.filedRequied = config.locale.filedRequied;
            }
        }

        this.resetPasswordLink.addEventListener('click', () => {
            modal.close('login');
        });

        this.registerButton.addEventListener('click', () => {
            modal.close('login');
        });
    }

    bindFormSubmit = () => {
        const { form, inputs, validation } = this;

        inputs.rememberMe.addEventListener('click', (e) => {
            inputs.rememberMe.value = (inputs.rememberMe.checked) ? 'true' : 'false';
        });

        form.addEventListener('submit', (e) => {
            try {
                e.preventDefault();

                if (validation.email && validation.password) {
                    this.login();
                } else {
                    this.validateEmail();
                    this.validatePassword();
                }
            } catch (error) {
                console.error(error);
            }
        });
    }

    login = async () => {
        const { form, inputs, locale } = this; 

        let cookieData = '';
        cookieData += "emailOnForm:" + inputs.email.value.trim() + ",";
        cookieData += "isRememberCookie:" + inputs.rememberMe.value + ";";

        let date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + date.toUTCString() + ";";
        document.cookie = 'loginForm=' + cookieData + expires + 'path=/;Secure;SameSite=None; Partitioned;';

        let formData = '';
        formData += `ReturnUrl=&`;
        formData += `Email=${encodeURIComponent(inputs.email.value.trim())}&`;
        formData += `Password=${encodeURIComponent(inputs.password.value)}&`;
        formData += `Remember=${inputs.rememberMe.checked}&`;
        formData += `WithoutRedirect=false`;

        const response = await fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Requested': 'With: XMLHttpRequest'
            }
        });
        const res = await response.text();

        if (res === '0') {
            location.reload();
        } else if (res === '1') {
            this.showFormSubmitError(locale.wrongLoginData);
        } else if (res === '2') {
            this.showFormSubmitError(locale.inactiveAccount);
        }
    }

    loadLoginCookieData = () => {
        const { inputs } = this; 
        const loginCookieData = this.getCookie('loginForm');
        const loginFormData = (loginCookieData !== null) ? loginCookieData.split(',') : [];

        let emailOnFormCookie = '';
        let isRememberCookie = '';

        loginFormData.forEach(function (value) {
            if (value.includes('emailOnForm')) {
                emailOnFormCookie = value.split(':')[1];
            }
            if (value.includes('isRememberCookie')) {
                isRememberCookie = value.split(':')[1];
            }
        });

        if (isRememberCookie === 'true') {
            if (emailOnFormCookie.length !== 0) {
                inputs.email.value = emailOnFormCookie;
                this.validateEmail();
            }
            if (isRememberCookie.length !== 0) {
                inputs.rememberMe.checked = true;
                inputs.rememberMe.value = true;
            }
        }
    }

    bindValidateOnChangeInputs = () => {
        const { email, password } = this.inputs;

        email.addEventListener('change', () => {
            this.validateEmail();
        });

        password.addEventListener('change', () => {
            this.validatePassword();
        });
    }

    validateEmail = () => {
        const { emailRegex, locale } = this;
        const { email } = this.inputs;

        if (this.isset(email.value.trim())) {
            if (emailRegex.test(email.value.trim())) {
                this.validation.email = true;
                this.removeInputError(email);
            } else {
                this.validation.email = false;
                this.addInputError(email, locale.wrongEmail);
            }
        } else {
            this.addInputError(email, locale.filedRequied);
        }
    }

    validatePassword = () => {
        const { locale } = this;
        const { password } = this.inputs;

        if (this.isset(password.value)) {
            this.validation.password = true;
            this.removeInputError(password);
        } else {
            this.validation.password = false;
            this.addInputError(password, locale.filedRequied);
        }
    }

    addInputError = (input, message) => {
        const inputContainer = input.parentElement;
        const element = inputContainer.querySelector(`.input__description`);
        if (element === null) {
            inputContainer.classList.add(this.errorElementClassName);
            input.parentElement.appendChild(this.getErrorElement(message));
        }
    }

    removeInputError = (input) => {
        const inputContainer = input.parentElement;
        const element = inputContainer.querySelector(`.input__description`);
        if (element !== null) {
            inputContainer.classList.remove(this.errorElementClassName);
            element.remove();
        }
    }

    getErrorElement = (message) => {
        const p = document.createElement('p');
        p.classList.add('input__description');
        p.innerHTML = message;

        return p;
    }

    showFormSubmitError = (message) => {
        const div = document.createElement('div');
        div.classList.add('form__main-error');

        const p = document.createElement('p');
        p.innerHTML = message;
        div.appendChild(p);

        this.form.appendChild(div);

        setTimeout(() => {
            div.classList.add('show');
        }, 100);

        setTimeout(() => {
            div.remove();
        }, 3000);
    }

    isset = (value) => {
        return !(!value || value === undefined || value === "" || value.length === 0 || value == null || value === null);
    }

    getCookie = (cname) => {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        let data = null;
        for (var i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                data = c.substring(name.length, c.length);
            }
        }
        return data;
    }
}